import Ajax from 'adaptive-ui/src/js/utils/ajax';

export default class OrderTransportAndPayment {

    constructor() {
        this.relations = {};

        const paymentTransportRelations = document.querySelector('[data-payment-transport-relations]');

        if (paymentTransportRelations) {
            const relationsData = JSON.parse(paymentTransportRelations.dataset.paymentTransportRelations);
            relationsData.forEach(item => {
                this.addTransportPaymentRelation(item.paymentId, item.transportId);
            });
        }

        this.showApplePayIfPossible();
    }

    addTransportPaymentRelation(paymentId, transportId) {
        if (!this.relations[paymentId]) {
            this.relations[paymentId] = {};
        }

        this.relations[paymentId][transportId] = true;
    }

    transportPaymentRelationExist(paymentId, transportId) {
        return this.relations[paymentId]?.[transportId] || false;
    }

    updateTransports() {
        const checkedPayment = document.querySelector('[data-order-payment-input]:checked');
        const transportInputs = document.querySelectorAll('[data-order-transport-input]');

        if (checkedPayment) {
            const checkedPaymentId = checkedPayment.dataset.id;

            transportInputs.forEach(input => {
                const id = input.dataset.id;

                if (this.transportPaymentRelationExist(checkedPaymentId, id)) {
                    input.disabled = false;
                    input.closest('.order__data-choice').classList.remove('order__data-choice--disabled');
                } else {
                    input.disabled = true;
                    input.closest('.order__data-choice').classList.add('order__data-choice--disabled');
                }
            });
        } else {
            transportInputs.forEach(input => {
                input.disabled = false;
                input.closest('.order__data-choice').classList.remove('order__data-choice--disabled');
            });
        }

        const checkedTransport = document.querySelector('.js-order-transport-input:checked');
        if (checkedTransport) {
            checkedTransport.closest('.order__data-choice').classList.remove('order__data-choice--disabled');
        }
    }

    updatePayments() {
        const checkedTransport = document.querySelector('[data-order-transport-input]:checked');
        const paymentInputs = document.querySelectorAll('[data-order-payment-input]');

        if (checkedTransport) {
            const checkedTransportId = checkedTransport.dataset.id;

            paymentInputs.forEach(input => {
                const id = input.dataset.id;

                if (!input.classList.contains('js-gopay-bank-selection')) {
                    if (this.transportPaymentRelationExist(id, checkedTransportId)) {
                        input.disabled = false;
                        input.closest('.order__data-choice').classList.remove('order__data-choice--disabled');
                    } else {
                        input.disabled = true;
                        input.closest('.order__data-choice').classList.add('order__data-choice--disabled');
                    }
                }
            });
        } else {
            paymentInputs.forEach(input => {
                input.disabled = false;
                input.closest('.order__data-choice').classList.remove('order__data-choice--disabled');
            });
        }

        const checkedPayment = document.querySelector('.js-order-payment-input:checked');
        if (checkedPayment) {
            checkedPayment.closest('.order__data-choice').classList.remove('order__data-choice--disabled');
        }
    }

    onTransportChanged(event) {
        this.updatePayments();

        Ajax.post(event.target.dataset.transportChangedUrl, {
            transportId: event.target.value
        }, { unique: false, history: false, loaderElement: false });
    }

    onPaymentChanged(event) {
        this.updateTransports();

        Ajax.post(event.target.dataset.paymentChangedUrl, {
            paymentId: event.target.value
        }, { unique: false, history: false, loaderElement: false });
    }

    showApplePayIfPossible() {
        const applePayChoice = document.querySelector('[data-apple-pay]');

        if (!applePayChoice || !window.ApplePaySession) {
            return;
        }

        if (location.protocol === 'https:' && !window.ApplePaySession.canMakePayments()) {
            return;
        }

        applePayChoice.classList.remove('display-none');
    }

    static init() {
        const transportInputs = document.querySelectorAll('[data-order-transport-input]');
        const paymentInputs = document.querySelectorAll('[data-order-payment-input]');
        const orderTransportAndPayment = new OrderTransportAndPayment();

        transportInputs.forEach(input => {
            input.addEventListener('change', (event) => orderTransportAndPayment.onTransportChanged(event));
        });

        paymentInputs.forEach(input => {
            input.addEventListener('change', (event) => orderTransportAndPayment.onPaymentChanged(event));
        });

        orderTransportAndPayment.updateTransports();
        orderTransportAndPayment.updatePayments();
    }
}

naja.addEventListener('init', () => {
    OrderTransportAndPayment.init();
});
